@import 'form_mixins';

.searchInput {
  .ais-SearchBox-input {
    @include vc-form-text-input;
    padding-left: 30px;
    -webkit-appearance: none;
    border-radius: 0;
    border-color: $vc-color-black-base;
  }

  .ais-SearchBox-submit {
    position: absolute;
    top: 20px;
    left: 40px;
    display: inline-block;
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
