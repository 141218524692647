/* FIRST INPUT TEXT STYLE -------------------------- */
.vc-form-text {
  width: 100%;
  position: relative;
  padding-top: 18px;
  overflow: hidden;

  &__label {
    display: block;
    position: absolute;
    top: 7px;
    font-size: 18px;
    line-height: 26px;
    color: $vc-color-grey-dark;
    transition: all 0.3s ease-in-out;
  }

  &__input {
    display: block;
    border: none;
    border-bottom: 1px solid $vc-color-grey-dark;
    border-radius: 0;
    width: 100%;
    outline: none;
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 4px;

    &:focus {
      border-color: $vc-color-brand;

      + .vc-form-text__label {
        color: $vc-color-brand;
      }

      ~ .vc-form-text__unity {
        border-color: $vc-color-brand;
      }
    }

    &:focus,
    &.notEmpty,
    &.ng-touched {
      + .vc-form-text__label {
        top: 0;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
      }
    }

    &.error,
    &.ng-invalid.ng-touched {
      border-color: $vc-color-error;

      + .vc-form-text__label {
        color: $vc-color-error;
      }

      ~ .vc-form-text__unity {
        border-color: $vc-color-error;
      }
    }
  }

  &__action {
    position: absolute;
    bottom: 9px;
    right: 0;
    color: $vc-color-black-light;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
  }

  &--withUnit {
    display: flex;
    flex-flow: row wrap;

    .vc-form-text {
      &__input {
        order: 1;
        width: auto;
        flex: 1;
      }

      &__label {
        order: 3;
      }

      &__unity {
        border-bottom: 1px solid $vc-color-grey-dark;
        order: 2;
      }
    }
  }

  &--noLabelAnimation {
    .vc-form-text__label {
      top: 0;
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
    }
  }
}

@include respond-to($md-up) {
  .vc-form-text {
    &__input {
      line-height: 21px;
      padding-bottom: 5px;
    }

    &__label {
      top: 18px;
      line-height: 21px;
    }

    &__action {
      bottom: 5px;
    }
  }
}
