@use 'sass:math';
@import 'global/mixins/mixins-scrollbar';

.vc-modal {
  &__innerContainer {
    width: 100%;
    height: 100%;
    color: $vc-color-black-off;

    &--full {
      padding: 0;
    }
  }
}

@include respond-to($md-up) {
  .vc-modal__innerContainer {
    height: auto;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/*------------------------------------------------------
  CLOSE BUTTON
--------------------------------------------------------*/

$vcModalCloseBtnSize: 1rem;

.vc-modal__closeBtn {
  display: block;
  position: absolute;
  right: 1rem;
  top: 0.75rem;
  width: $vcModalCloseBtnSize;
  height: $vcModalCloseBtnSize;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-appearance: none;
  cursor: pointer;
  z-index: $modal-depth;
  padding: calc(2.75rem - #{$vcModalCloseBtnSize});

  &__text {
    font-size: 0.75rem;
    font-weight: bold;
  }

  &__cross {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    color: $vc-color-black-dark;
    width: 1rem;
    height: 1rem;
    transform: translate3d(-50%, -50%, 0);
  }

  &__smallCross {
    position: relative;
    top: 0;
    left: 0;
    width: 0.5rem;
    height: 0.5rem;
    margin-left: 0.5rem;
  }

  &--withText {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: auto;
    height: auto;
    padding: 1.5rem 2.5rem 0 0;
  }
}

.vc-modal__closeBtn__crossLeft,
.vc-modal__closeBtn__crossRight {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1px;
  height: $vcModalCloseBtnSize;
  border: none;
  background-color: $vc-color-black-dark;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-appearance: none;
}

.vc-modal__closeBtn__crossLeft {
  transform: rotate(45deg) translate3d(-50%, -55%, 0);
  left: calc(50% - #{math.div($vcModalCloseBtnSize, 4)});
}

.vc-modal__closeBtn__crossRight {
  transform: rotate(-45deg) translate3d(50%, -55%, 0);
  left: calc(50% + #{math.div($vcModalCloseBtnSize, 2)});
}

/*------------------------------------------------------
  MODAL CONTENT
--------------------------------------------------------*/
.vc-modal__title {
  display: block;
  line-height: 1;
  font-family: $vc-font-headings;
  font-weight: normal;
  color: $vc-color-text-base;
  font-size: 1.5rem;
  margin-right: 1rem; // prevents overlap with close button
}

.vc-modal__content {
  margin-top: 40px;
}

@include respond-to('(max-width:#{lower-bound($md-range)})') {
  .vc-modal__closeBtn {
    padding: calc(1.75rem - #{$vcModalCloseBtnSize});
  }
}
