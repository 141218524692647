@import '../../node_modules/@vestiaire/design-system/scss/mixins';
@import '../../node_modules/@vestiaire/design-system/scss/variables';

$koreanFooter: -apple-system, 'Malgun Gothic', 'Apple SD Gothic Neo', sans-serif;

.ko-KR {
  font-family: $koreanFooter;
}

.impressionTrackingContainer {
  height: 100%;
}
