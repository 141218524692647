@import 'global/form/form-text';
@import 'global/form/form-select';
@import 'global/form/form-checkbox';
@import 'global/form/form-searchbar';
@import 'global/form/form-checkbox-switch';
@import 'global/form/form-radio';

.vc__label {
  display: block;
  font-weight: 500;
  text-transform: uppercase;
  color: #ababab;
  letter-spacing: 0.1em;
  font-size: 13px;
  line-height: 16px;
  padding-bottom: 10px;
}

.vc-form__error {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: $vc-color-error;
}
