$properties: none, inline, block, table, table-row, table-cell, flex, inline-flex, grid;
$sizes: (
  'sm': 576px,
  'md': 768px,
  'lg': 1024px,
  'xl': 1440px,
);

@each $property in $properties {
  .d-#{$property} {
    display: #{$property} !important;
  }
}

@each $sizePrefix, $size in $sizes {
  @media (min-width: $size) {
    @each $property in $properties {
      .d-#{$sizePrefix}-#{$property} {
        display: #{$property} !important;
      }
    }
  }
}
