
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.dropdown {
  &__container {
    position: relative;
  }

  &__inputText {
    width: 100%;
    background-color: $vc-color-g5;
    border: 1px solid $gray20;
    padding: 0.75rem;
    height: 3rem;
    box-sizing: border-box;
    line-height: 1.5rem;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    color: $gray100;
    font-family: HelveticaNowText, Helvetica, sans-serif;

    &--container {
      position: relative;
      display: inline-block;
    }

    &--focused {
      border: 1px solid $gray90;
      color: $base-black;
    }

    &--hasValue {
      background-color: $vc-color-g0;
    }

    &--withImg {
      padding-left: 2.2rem;
    }

    &__buttons__container {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1rem;
      height: 1.25rem;
    }

    &__icon__buttons {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 3rem;
    }

    &__icon__button {
      height: 100%;
      cursor: pointer;

      &--focused {
        visibility: visible;
      }

      &--close {
        visibility: hidden;
      }
    }

    &::placeholder {
      background-size: 1.5rem 1.5rem;
      background-repeat: no-repeat;
      background-position: left;
      color: $vc-color-g60;
    }

    &:focus {
      background-color: white;
      outline: none;
    }
  }

  &__optionsList {
    width: 100%;
    z-index: 1001;
    background-color: white;
    border: 1px solid $gray10;
    left: 0;
    border-radius: 0.25rem;
    position: absolute;
    top: calc(100% + 0.25rem);
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.06);
    max-height: 18rem;
    overflow-y: scroll;

    &__icon {
      width: 20px;
      height: 20px;
    }

    &__item {
      cursor: pointer;
      width: 100%;
      margin: 0;
      padding: 0.625rem 0.75rem;
      color: $gray90;

      &:hover,
      &--selected {
        background-color: $gray5;
      }

      &--selectedIcon {
        width: 20px;
        height: 20px;
        float: right;
      }

      &--content {
        display: inline-flex;
      }

      &--strong {
        font-weight: 700;
      }

      &--noResults {
        text-align: center;
        padding: 0.625rem;
        margin-bottom: 0.75rem;
        color: $gray40;
      }
    }
  }
}
