.vc-form-checkbox-switch {
  border-bottom: 1px solid $vc-color-grey-light;
}

.vc-form-checkbox-switch__input {
  display: none;
}

.vc-form-checkbox-switch__label {
  position: relative;
  display: block;
  padding: 13px 78px 13px 0;
  font-size: 14px;
  line-height: 20px;
}

.vc-form-checkbox-switch__label::before {
  box-sizing: border-box;
  display: block;
  content: '';
  position: absolute;
  top: 12px;
  right: 0;
  width: 38px;
  height: 23px;
  border: 1px solid $vc-color-grey-light;
  border-radius: 12px;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  background: #fff;
  cursor: pointer;
}

.vc-form-checkbox-switch__label::after {
  box-sizing: border-box;
  display: block;
  content: '';
  position: absolute;
  top: 13px;
  right: 0;
  width: 20.7px;
  height: 20.7px;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 2px 1px 0 rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, border-size 0.3s ease-in-out;
  transform: translateX(-17px);
  cursor: pointer;
}

.vc-form-checkbox-switch__input:checked {
  + .vc-form-checkbox-switch__label::before {
    background: $vc-color-brand;
    border-color: $vc-color-brand;
  }

  + .vc-form-checkbox-switch__label::after {
    transform: translateX(-1px);
    border: 0;
  }
}

@include respond-to($md-up) {
  .vc-form-checkbox-switch__label {
    font-size: 15px;
    padding-right: 50px;
  }
}
