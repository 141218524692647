
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
.button {
  &--notfull {
    width: auto;

    @include respond-to($sm-down) {
      &--sm-down {
        width: auto;
      }
    }

    @include respond-to($sm-up) {
      &--sm-up {
        width: auto;
      }
    }

    @include respond-to($md-up) {
      &--md-up {
        width: auto;
      }
    }

    @include respond-to($lg-up) {
      &--lg-up {
        width: auto;
      }
    }
  }
}

.icon {
  display: inline-block;
  width: 0.9375rem;
  height: 0.75rem;
  margin-right: 0.5rem;
  line-height: 0;
}
