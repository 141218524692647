.formRadio__label {
  padding: 20px 0;
  cursor: pointer;
  font-size: 16px;
}

.formRadio__label::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -3px;
  margin-right: 10px;
  box-sizing: border-box;
  vertical-align: middle;
  background: white;
  border: 1px solid $vc-color-grey-light;
  border-radius: 50%;
}

.formRadio__input {
  display: none;

  &:checked + .formRadio__label::before {
    background: $vc-color-brand;
    box-shadow: inset 0 0 0 4px #fff;
  }

  &:disabled + .formRadio__label {
    color: lighten($vc-color-black-base, 60%);
    cursor: default;
  }
}

@include respond-to($md-up) {
  .formRadio__label::before {
    width: 16px;
    height: 16px;
  }
}
