@import '../../node_modules/@vestiaire/design-system/scss/variables';
@import '../../node_modules/@vestiaire/design-system/scss/mixins';

$FullWidthAccessibilityLinkHeight: 1.5rem;
$FloatAccessibilityLinkHeight: 2.5rem;
$SmartBannerHeight: 5rem;

/* Start of accessibility Link */
.accessibilityTopLink {
  padding: 0 1rem;
  background: $vc-color-black-base;
  text-align: center;
  z-index: $navigation-depth;
  color: white;
  font-size: 0.875rem;
  height: $FullWidthAccessibilityLinkHeight;
  display: block;
  width: 100%;
}

@include respond-to($md-up) {
  .hasBanner .accessibilityTopLink {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: auto;
    border-radius: 4px;
    text-align: left;
    height: $FloatAccessibilityLinkHeight;
    padding: 0.5rem;
  }
}
/* End of accessibility Link */

/* Start of CMS page banner section */
/* To set the top space above banners when accessibility link and smart banner exit or not */
/* Main idea of this solution is that accessibility link, banner and smart banner all have fixed
  width and fixed css name, so we determine the top value by quering corresponding css names
*/
.infoBanner {
  height: 2.5rem;
}

.vc-banner__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  @extend .infoBanner;
}

.hasBanner:not(.hasAccessibilityLink) .hasSmartBanner ~ .vc-banner__container,
.hasBanner:not(.hasAccessibilityLink) .hasSmartBanner ~ * .vc-banner__container {
  top: $SmartBannerHeight;
}

.hasAccessibilityLink :not(.hasSmartBanner) ~ .vc-banner__container,
.hasAccessibilityLink :not(.hasSmartBanner) ~ * .vc-banner__container {
  top: $FullWidthAccessibilityLinkHeight;
}

.hasAccessibilityLink .hasSmartBanner ~ .vc-banner__container,
.hasAccessibilityLink .hasSmartBanner ~ * .vc-banner__container {
  top: calc($SmartBannerHeight + $FullWidthAccessibilityLinkHeight);
}

@include respond-to($sm-down) {
  .hasBanner:not(.hasAccessibilityLink) .notifications-box {
    top: 0;
  }
}

@include respond-to($md-up) {
  .hasAccessibilityLink :not(.hasSmartBanner) ~ .vc-banner__container,
  .hasAccessibilityLink :not(.hasSmartBanner) ~ * .vc-banner__container {
    top: 0;
  }

  .cmsPreview .vc-banner__container {
    top: 69px;
  }
}
/* End of CMS page banner section */

/* Fix for responsive issue */
@include respond-to($md-up) {
  .hasSmartBanner {
    display: none !important;
  }

  .hasAccessibilityLink ~ .hasSmartBanner ~ .vc-banner__container,
  .hasAccessibilityLink ~ .hasSmartBanner ~ * .vc-banner__container {
    top: 0;
  }
}
