
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.qa.vestiairecollective.com/a/fenx";
      
@import 'form';
@import 'sizes';
@import 'modal';
@import 'loader';
@import 'banner';

html {
  scroll-behavior: initial;
}

#launcher {
  bottom: 67px !important;
  @include respond-to($lg-up) {
    bottom: 16px !important;
  }
}

.innerContainer {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  padding-left: $outerPadding-md;
  padding-right: $outerPadding-md;

  @include respond-to($xl-up) {
    max-width: lower-bound($xlg-range);
  }
}

body {
  font-size: 16px;

  &.ReactModal__Body--open {
    position: fixed;
    overflow-y: hidden;
  }

  &.PlpModal__Body--open {
    overflow-y: hidden;
  }
}

#popin_tc_privacy {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}

#nprogress .spinner-icon {
  display: none !important;
}
