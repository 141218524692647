.vc__select {
  position: relative;
}

.vc__select select {
  display: block;
  width: 100% !important; // sass-lint:disable-line no-important
  max-width: 100%;
  height: 44px;
  box-sizing: border-box;
  padding: 0 28px 0 14px;
  vertical-align: top;
  cursor: pointer;
  background: url('data:image/svg+xml;utf8,<svg class="svg_arrow_down" viewBox="0 0 20 12" width="12px" height="8px" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(0.000000, 1.000000)" stroke="rgb(43, 43, 43)"><polyline transform="translate(10.000000, 5.000000) rotate(90.000000) translate(-10.000000, -5.000000) " points="5.0641949 -5 15 4.96779919 5 15"></polyline></g></g></svg>')
    no-repeat right 16px top 50%;
  border: solid 1px #eaeaea;
  border-radius: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: $vc-font-body;
  font-size: 18px;
  line-height: 44px;
  color: #000;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
}

.vc__select option {
  -webkit-appearance: none;
  vertical-align: top;
}

.vc__select select::first-letter,
.vc__select option::first-letter {
  text-transform: uppercase;
}

@include respond-to($md-up) {
  .vc__select {
    background-size: 12px 8px;
    background-position: right 12px top 17px;
  }

  .vc__select select {
    height: 40px;
    padding: 0 28px 0 14px;
    font-size: 15px;
    line-height: 20px;
  }
}
