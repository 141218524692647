@mixin vc-form-text-input {
  display: block;
  border: none;
  font-size: 18px;
  line-height: 21px;
  padding-bottom: 2px;
  border-bottom: 1px solid $vc-color-grey-dark;
  border-radius: 0;
  width: 100%;
  outline: none;
}
